import css from './About.module.scss'
import global from '../../../styles/Global.module.scss'

import dynamic from "next/dynamic";
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";
import Link from "next/link";
const DynamicAboutSlider = dynamic(() => import('./AboutSlider'), {ssr: false})

const items = [
    {id:1, path: '/files/images/main/main-architecture.jpg', title: 'Архитектура', text: 'Смотреть галерею', dark: false},
    {id:2, path: '/files/images/main/main-yard.jpg', title: 'Двор', text: 'Смотреть галерею', dark: false},
    {id:3, path: '/files/images/main/main_infrastructure_slider.jpg', title: 'Инфраструктура', text: 'Смотреть галерею', dark: false},
    {id:4, path: '/files/images/main/main-lobby.jpg', title: 'Лобби', text: 'Смотреть галерею', dark: false},
    // {id:4, path: '/files/images/main/main-finish.jpg', title: 'Отделка', text: 'Смотреть галерею', dark: false},
    // {id:5, path: '/files/images/main/main-views.jpg', title: 'Виды из окон', text: 'Смотреть галерею', dark: false},
    // {id:6, path: '/files/images/main/main-style.jpg', title: 'Окружение', text: 'Смотреть галерею', dark: false}
]

const About = ({title, text, slides}) => {

    //animation
    const ref = useRef(null)
    const {isVisible: inView} = useVisible(ref)
    //

    const [visible, setVisible] = useState(false)
    const [currentNumber, setCurrentNumber] = useState(1)

    useEffect(() => {
        inView && !visible && setVisible(true)
    }, [inView])

    return (
        <section className={css.about} ref={ref}>

            <div className={`${global.section} ${css.section}`}>

                <div className={inView ? `main-page__item ${css.block} ${css.animation}` :`main-page__item ${css.block}`}>
                    <h2 className='main-page__title main-page__about' dangerouslySetInnerHTML={{__html:title}}></h2>
                    <div className={css.desc} dangerouslySetInnerHTML={{__html:text}}></div>
                </div>

                <div className={inView ? `main-page__item ${css.slider} ${css.blockTwo} ${css.animationTwo}` :`main-page__item ${css.slider} ${css.blockTwo}`}>
                    {visible && <DynamicAboutSlider items={slides} updateCurrentNumber={(counter) => setCurrentNumber(counter)}/>}
                    <Link href={"/gallery"}>
                        <a className={`${css.button } btn`}>Смотреть галерею</a>
                    </Link>
                    <div className={css.counter}>{currentNumber}/{slides.length}</div>
                </div>

            </div>

        </section>
    )
}

export default About